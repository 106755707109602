import { ITranslation } from 'i18nConfig';
import translationEN from './en.json';
import translationES from './es.json';

export const translations: ITranslation = {
    namespace: 'feedbackWeb',
    translations: {
        en: translationEN,
        es: translationES,
    },
};

export default translations.namespace;
