import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validate as uuidIsValid } from 'uuid';
import { StatusPage } from 'spoton-lib';

import FeedbackContainer from '../FeedbackContainer';
import {
    type IFeedbackContainerProps,
    ReactionType,
} from '../FeedbackContainer/FeedbackContainer.types';

export function ParamsValidator(): JSX.Element | null {
    const [data, setData] = useState({} as IFeedbackContainerProps);
    const [isParamsValid, setIsParamsValid] = useState(false);
    const [isValidatingInProgress, setIsValidatingInProgress] = useState(true);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const invitationId = searchParams.get('invitationId');
        const thumbs = searchParams.get('thumbs') as ReactionType;

        navigate('/', { replace: true });

        if (
            !!invitationId &&
            uuidIsValid(invitationId) &&
            !!thumbs &&
            Object.values(ReactionType).includes(thumbs)
        ) {
            setData({ invitationId, thumbs });
            setIsParamsValid(true);
        }

        setIsValidatingInProgress(false);
    }, []);

    if (isValidatingInProgress) {
        return null;
    }

    return isParamsValid ? (
        <FeedbackContainer
            invitationId={data.invitationId}
            thumbs={data.thumbs}
        />
    ) : (
        <StatusPage />
    );
}

export default ParamsValidator;
