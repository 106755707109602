import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { StatusPage } from 'spoton-lib';
import { FaroRoutes } from '@grafana/faro-react';

import { getIsProdOrStaging } from 'features/common';
import { ParamsValidator } from '../components/ParamsValidator';

const RoutesComponent = getIsProdOrStaging() ? FaroRoutes : Routes;

export const routes = (
    <RoutesComponent>
        <Route path="/" element={<ParamsValidator />} />
        <Route path="*" element={<StatusPage />} />
    </RoutesComponent>
);

export default routes;
