import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextArea } from 'spoton-lib';

import shareThoughtsImage from '../../../images/share-thoughts.svg';
import { type IShareThoughtsPageProps } from './ShareThoughtsPage.types';

import styles from './ShareThoughtsPage.module.scss';

export function ShareThoughtsPage({
    text,
    onTextChange,
    onSubmit,
}: IShareThoughtsPageProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className={styles.Page}>
            <img
                src={shareThoughtsImage}
                alt={t('SHARE_THOUGHTS')}
                className={styles.Page_image}
            />
            <div className={styles.Page_title}>{t('SHARE_THOUGHTS')}</div>
            <div className={styles.Page_subtitle}>
                {t('YOUR_FEEDBACK_HELPS_US')}
            </div>
            <TextArea
                className={styles.Page_textAreaWrapper}
                textAreaClassName={styles.Page_textArea}
                value={text}
                onChange={onTextChange}
            />
            <Button className={styles.Page_submitButton} onClick={onSubmit}>
                {t('SUBMIT')}
            </Button>
        </div>
    );
}

export default ShareThoughtsPage;
