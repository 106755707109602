import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { feedbackWebTranslations } from 'app/i18n';
import { ENV } from 'features/common';

const { namespace, translations } = feedbackWebTranslations;
const resources = {
    en: {
        [namespace]: translations.en,
    },
    es: {
        [namespace]: translations.es,
    },
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        defaultNS: namespace,
        // lng: 'en', // leave out for browser detection
        fallbackLng: 'en',
        debug: import.meta.env.MODE === ENV.DEVELOPMENT, // true only for local development

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        detection: {
            // order and from where user language should be detected
            order: [
                'navigator',
                'querystring',
                'cookie',
                'localStorage',
                'sessionStorage',
                'htmlTag',
                'path',
                'subdomain',
            ],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language
            caches: [],
            excludeCacheFor: [],
        },
    });

export default i18n;
