import React, { SyntheticEvent, useState, useEffect } from 'react';
import { StatusPage, LoadingOverlay } from 'spoton-lib';

import { axios } from '../../../features';
import ThankYouPage from '../ThankYouPage';
import ShareThoughtsPage from '../ShareThoughtsPage';
import { RATINGS } from './FeedbackContainer.utils';
import { type IFeedbackContainerProps } from './FeedbackContainer.types';

import styles from './FeedbackContainer.module.scss';

export function FeedbackContainer({
    invitationId,
    thumbs,
}: IFeedbackContainerProps): JSX.Element {
    const [isCreatingReview, setIsCreatingReview] = useState(true);
    const [isCreatingReviewError, setIsCreatingReviewError] = useState(false);
    const [text, setText] = useState('');
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
    const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                await axios.post(`/api/v1/customers/reviews`, {
                    invitationId,
                    rating: RATINGS[thumbs],
                });
            } catch (error) {
                setIsCreatingReviewError(true);
            } finally {
                setIsCreatingReview(false);
            }
        })();
    }, []);

    const handleTextChange = (event: SyntheticEvent) => {
        setText((event.target as HTMLTextAreaElement).value);
    };

    const handleSubmit = async () => {
        setIsSubmittingFeedback(true);

        try {
            await axios.post(`/api/v1/customers/reviews`, {
                invitationId,
                comment: text,
                rating: RATINGS[thumbs],
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmittingFeedback(false);
            setIsFeedbackSubmitted(true);
        }
    };

    if (isCreatingReviewError) {
        return <StatusPage />;
    }

    if (isCreatingReview) {
        return <LoadingOverlay hasBackgroundBlur />;
    }

    return (
        <div className={styles.FeedbackContainer}>
            {isSubmittingFeedback && <LoadingOverlay hasBackgroundBlur />}
            {isFeedbackSubmitted ? (
                <ThankYouPage />
            ) : (
                <ShareThoughtsPage
                    text={text}
                    onTextChange={handleTextChange}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
}

export default FeedbackContainer;
