import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    Routes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import {
    getWebInstrumentations,
    initializeFaro,
    ReactIntegration,
    createReactRouterV6Options,
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

import {
    ErrorBoundaryGeneric,
    getConfigVar,
    getIsProdOrStaging,
} from 'features';

import { App } from 'app/components';
// Global styles
import './index.css';

if (getIsProdOrStaging()) {
    const instrumentationOptions = {
        propagateTraceHeaderCorsUrls: [
            new RegExp('\\.spoton\\.sh|\\.spoton\\.com'),
        ],
    };

    initializeFaro({
        url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/542834c1d74ddda925a282a5f8f7450a',
        app: {
            name: 'feedback-web',
            version: getConfigVar('REACT_APP_VERSION'),
            environment: getConfigVar('REACT_APP_NODE_ENV'),
        },
        instrumentations: [
            // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
            ...getWebInstrumentations(),

            // Initialization of the tracing package.
            // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
            new TracingInstrumentation({ instrumentationOptions }),
            new ReactIntegration({
                router: createReactRouterV6Options({
                    createRoutesFromChildren,
                    matchRoutes,
                    Routes,
                    useLocation,
                    useNavigationType,
                }),
            }),
        ],
    });
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ErrorBoundaryGeneric>
                <App />
            </ErrorBoundaryGeneric>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
