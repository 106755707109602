export const ENV = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production',
    STAGING: 'staging',
    TEST: 'test',
};

/**
 * A helper for getting an env variable.
 *
 * When our project gets built for production we switch from the usual build
 * time ENV vars to run time variables injected via our build scripts, nginx,
 * and including it in the project's header.
 *
 * This allows us to set ENV vars whenever we want without the need to rebuild
 * and deploy, however it does mean a little extra work to get the right thing.
 * This method helps take out the guesswork, and gives a nice warning if a
 * variable is not set properly.
 *
 * @see /public/index.html
 * @see /env.sh
 */
export function getConfigVar(key: string): string {
    let env = import.meta.env; // default env
    let configVar = '';
    let isProd = false;

    // skip getting env variables when running tests
    if (env.MODE === ENV.TEST) {
        return configVar;
    }

    const isRuntimeEnv = env.MODE === ENV.PRODUCTION;

    if (isRuntimeEnv) {
        env = (window as any)._feedback_env_;
        isProd = env.REACT_APP_NODE_ENV === ENV.PRODUCTION;
    }

    if (env[key]) {
        configVar = env[key] as string;
    } else if (!isProd) {
        console.warn(`[Warning] Environment variable '${key}' is not set`);
    }

    return configVar;
}

/**
 * A helper for checking if the environment is prod or staging
 * @returns {boolean}
 */
export const getIsProdOrStaging = (): boolean => {
    const envs = [ENV.PRODUCTION, ENV.STAGING];

    return envs.includes(getConfigVar('REACT_APP_NODE_ENV'));
};
