import React from 'react';
import { useTranslation } from 'react-i18next';

import thankYouImage from '../../../images/thank-you.svg';

import styles from './ThankYouPage.module.scss';

export function ThankYouPage(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className={styles.Page}>
            <img
                src={thankYouImage}
                alt={t('THANK_YOU_FOR_FEEDBACK')}
                className={styles.Page_image}
            />
            <div className={styles.Page_title}>
                {t('THANK_YOU_FOR_FEEDBACK')}
            </div>
            <div className={styles.Page_subtitle}>
                {t('IT_HELPS_US_CONTINUE')}
            </div>
        </div>
    );
}

export default ThankYouPage;
